import type {
  CommonParams,
  CommonResponse,
  FilterParams,
  FilterRentalParams,
  Language,
} from 'types/common';
import type {
  AcceptedTerm,
  ConfirmationDocument,
  DeliveryNoteDocument,
  RentalContractDocument,
  SuccessCommissionDocument,
} from 'types/documents';
import HttpClient from 'utils/HttpClient';

export interface TransportConfirmations {
  id: number;
  transportNo: string;
  pickupDateTime: string;
  deliveryDateTime: string;
  constructionCompany: string;
  forwardingCompany: string;
  transportPrice: number;
}
export interface Bid {
  amount: number;
  bidCompanyId: number;
  bidCompanyName: string | null;
  currency: string;
  id: number;
  isOffderSelected: boolean;
  status: string | null;
}

interface Company {
  companyName: string | null;
  contactName: string | null;
  companyCity: string | null;
  companyStreet: string | null;
  companyAdress: string | null;
  companyCountry: string | null;
  companyEmail: string | null;
  companyMobile: string | null;
}

// Get list confirmation document
const getListConfirmation = async (params: FilterParams) => {
  return HttpClient.post<typeof params, CommonResponse<ConfirmationDocument[]>>(
    '/shared/Document/ListConfirmation',
    params
  );
};
// Get list rental contract
const getListRentalContract = async (params: FilterRentalParams) => {
  return HttpClient.post<
    typeof params,
    CommonResponse<RentalContractDocument[]>
  >('/shared/Document/ListRental', params);
};

// Get list delivery note document
const getListDeliveryNoteDocument = async (params: FilterParams) => {
  return HttpClient.post<typeof params, CommonResponse<DeliveryNoteDocument[]>>(
    '/shared/Document/ListDeliveryNotes',
    params
  );
};

interface Carrier {
  companyName: string | null;
  contactName: string | null;
  companyCity: string | null;
  companyStreet: string | null;
  companyAdress: string | null;
  companyCountry: string | null;
  companyEmail: string | null;
  companyMobile: string | null;
  companyMobileCode: string | null;
}

interface Shipper {
  companyName: string;
  contactName: string | null;
  companyCity: string;
  companyStreet: string;
  companyAdress: string;
  companyCountry: string;
  companyEmail: string;
  companyMobile: string | null;
  companyMobileCode: string | null;
}

interface Contact {
  contactName: null;
  position: string;
  companyName: string;
  address: string;
  country: string | null;
  emailAddress: string;
  mobileNumber: string;
  phoneNumber: string;
}

interface Location {
  locationName: string;
  locationStreet: string;
  locationCity: string;
  locationCountry: string | null;
  contactName: string | null;
  emailAddress: string;
  phoneNumber: string;
}

export interface transportLoadItem {
  id: number;
  transportLoad: string;
  brandName: string;
  productFamilyName: string;
  vehicleTypeName: string | null;
  freightCategoryName: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  type: string;
  notes: string | null;
  contact: Contact;
  pickupLocation: Location;
  deliveryLocation: Location;
  pickUpDate: string;
  deliveryDate: string;
}

export interface DocumentDetails {
  deliveryTime: string | null;
  pickupTime: string | null;
  carrier: Carrier;
  shipper: Shipper;
  transportLoad: transportLoadItem[];
  transportCosts: number;
  regulations: string | null;
  tenderId: number;
  transportNo: string;
  tenderStartDate: string;
  tenderEndDate: string;
  pickUpDateTime: string | null;
  deliveryDateTime: string | null;
  companyForwarding: Company;
  companyContruction: Company;
  transportPrice: number;
  releaseDate: string;
  transportCurrency: string;
}

interface DocumentDetailsResponse {
  data: DocumentDetails;
  total: number;
}

export const getDocumentByTenderId = async (id: string) => {
  return HttpClient.get<number, DocumentDetailsResponse>(
    `/shared/Document/GetDocumentByTenderId?TenderId=${id}`
  );
};

export const getDocumentPdf = async (id: number) => {
  return HttpClient.get<number, string | null>(
    `/shared/Document/GetDocumentPdf?documentId=${id}`
  );
};

export const getDocumentByRentId = async (id: number) => {
  return HttpClient.get<number, CommonResponse>(
    `/company/Rent/GetPdfByRentId?rentId=${id}`
  );
};

export interface FileUrl {
  name: string;
  url: string;
}

interface TermTranslation {
  languageId: number | null;
  content: string;
}

export interface CreateTermFileParams {
  countryId: number | null;
  termContents: TermTranslation[];
}

export const createTermsFile = async (params: CreateTermFileParams) => {
  return HttpClient.post<CreateTermFileParams, CommonResponse<FileUrl>>(
    '/admin/configuration/Term/CreateTermFile',
    params
  );
};

export interface UpdateTermFileParams extends CreateTermFileParams {
  id: number;
}

export const updateTermFile = async (params: UpdateTermFileParams) => {
  return HttpClient.post<UpdateTermFileParams, CommonResponse<FileUrl>>(
    '/admin/configuration/Term/UpdateTermFile',
    params
  );
};

interface CreateTermParam {
  title: string;
  releaseDate: string;
  version: string;
  url: string;
  type: string;
  content: string;
  languageId: number | null;
}

export interface Term {
  id: number;
  countryId: number;
  isActive: true;
  releaseDate: string;
  createdDate: string;
  version: string;
  title: string;
  acceptDate: string;
  isLoading: boolean;
  isPublish: boolean;
  status: string;
  termTranslations: TermTranslate[];
}

export const createTerm = async (params: CreateTermParam) => {
  return HttpClient.post<CreateTermParam, CommonResponse<Term>>(
    '/shared/Document/CreateTerm',
    params
  );
};

export interface TermResponse {
  id: number;
  countryId: number;
  isActive: true;
  releaseDate: string;
  version: string;
  title: string;
  acceptDate: string;
  isLoading: boolean;
  isPublish: boolean;
  status: string;
  countryName: string;
  createdDate: string;
  termTranslations: {
    content: string;
    languageCode: string;
    languageId: number | null;
    pathFile: string;
    url: string;
    title: string;
    version: string;
  }[];
}

interface ListTermResponse {
  data: TermResponse[];
  total: number;
}

export const getListTerm = async (params: CommonParams) => {
  return HttpClient.post<typeof params, ListTermResponse>(
    '/admin/configuration/Term/ListTerm',
    params
  );
};

// Get list document success commission
interface IFilters extends FilterParams {
  statuses: string[];
}

const getListDocumentSuccessCommisstion = async (params: IFilters) => {
  return HttpClient.post<
    typeof params,
    CommonResponse<SuccessCommissionDocument[]>
  >('/shared/Document/ListSuccessCommission', params);
};

export const getListDocumentSuccessFeesByAccount = async (accountId: number, params: IFilters) => {
  return HttpClient.post<typeof params, CommonResponse<SuccessCommissionDocument[]>>(
    `/shared/Document/ListSuccessFeesByAccount?accountId=${accountId}`,
    params
  );
};

export interface TermTranslate {
  content: string;
  languageCode: string;
  languageId: number | null;
  pathFile: string;
  url: string;
  title: string;
  version: string;
}
export interface LastAcceptTerm {
  id: number;
  title: string;
  releaseDate: string;
  createdDate: string;
  version: string;
  termTranslations: TermTranslate[];
}

interface CheckLastAcceptTermResponse {
  data: {
    isAcceptedAll: boolean;
    unacceptedTerms: LastAcceptTerm[];
  };
}

export const checkLastAcceptTerm = async () => {
  return HttpClient.get<null, CheckLastAcceptTermResponse>(
    '/shared/Document/CheckUserTermAccept'
  );
};

export const acceptTerm = async (id: number) => {
  return HttpClient.put<number, CommonResponse>(
    `/shared/Document/AcceptTerm?termId=${id}`
  );
};

// Get list accepted term by user
const getListAcceptedTermByUser = (params: CommonParams) => {
  return HttpClient.post<typeof params, CommonResponse<AcceptedTerm[]>>(
    '/shared/Document/ListAcceptedTermByUser',
    params
  );
};

// khong tach file, de day thoi.
// tao ra thu muc type roi con de day

export const publishTerm = (termId: number) => {
  return HttpClient.put<CommonParams, CommonResponse>(
    `/admin/configuration/Term/PublishTerm?termId=${termId}`
  );
};

export const deactiveTerm = (termId: number) => {
  return HttpClient.put<CommonParams, CommonResponse>(
    `/admin/configuration/Term/DeactiveTerm?termId=${termId}`
  );
};

export const getTermById = (id: string) => {
  return HttpClient.get<string, CommonResponse<Term>>(
    `/admin/configuration/Term/GetTerm?termId=${id}`
  );
};

export const getTermRequiredLanguage = (id: number) => {
  return HttpClient.get<number, CommonResponse<Language[]>>(
    `/admin/configuration/Term/GetTermRequiredLanguage?countryId=${id}`
  );
};

export {
  getListDocumentSuccessCommisstion,
  getListConfirmation,
  getListDeliveryNoteDocument,
  getListAcceptedTermByUser,
  getListRentalContract,
};


